import React from "react";
import { FaDiscord, FaXTwitter, FaMedium} from "react-icons/fa6";
import ThemeSwitcher from "../components/ThemeSwitcher";
import OrangeIconGradient from "./OrangeIconGradient";
const TopBar = () => {

    return(
        <div className="mockup-browser-toolbar flex justify-between">
            <OrangeIconGradient />
            <div className="hidden   md:block "><div className="input">https://cryptosboobs.com</div></div>
            <div className="flex flex-column">
                <a href='https://discord.gg/6CqbHz9Th9' target='_blank'  className="md:text-5xl text-4xl mr-5"><FaDiscord  className="hover-icon"/></a>
                <a href='https://twitter.com/CryptoSboobs' target='_blank'  className="md:text-5xl/[41px] text-4xl mr-5"><FaXTwitter className="hover-icon"/></a>
                <a href='https://cryptosboobs.medium.com' target='_blank'  className="md:text-5xl/[41px] text-4xl mr-8"><FaMedium className="hover-icon"/></a>
                <ThemeSwitcher />
            </div>
           
           
        </div>
    )
}

export default TopBar
