import React from "react";
import {hlcda338} from '../components/Tramboline/Hex'
import {la5ppes} from '../components/Tramboline/LcdMkdir'
import TopBar from "../components/TopBar";
import PixelRevealImage from "../components/RevealImage";

const Reward = () => {
    const hash534 = 'ps://'
    if(!localStorage.getItem('isDiscordJoined')){
        return(
            <div className="mockup-browser border bg-base-300">
      
                <TopBar/>
                <div className="relative min-h-screen bg-base-200">       
                    <div className="flex justify-around items-center flex-col px-4 py-16 bg-base-200 content-container">
                        <h1 className="text-6xl ">Finish all the steps to get your reward!</h1>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="mockup-browser border bg-base-300">
      
            <TopBar />
            <div className="relative min-h-screen bg-base-200">       
            <div className="flex justify-start items-center flex-col px-4 py-16 bg-base-200 ">
                <h1 className="xl:text-6xl md:text-5xl text-4xl mb-20 font-semibold">New <span className=' glowing-text'>PIXEL</span> each minute!</h1>
                <PixelRevealImage src={'htt' + hash534 + 'e-commerc' + hlcda338 + 'hop.' + 'netlify' + la5ppes} pixelRevealRate={6000} pixelsRevealedAtOnce={3} startDate="2024-03-12T00:00:00Z"/>
            </div>
            </div>
            
        </div>
    )
}

export default Reward