import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { sdScan059 } from './Tramboline/Lexus';
import { tumb } from './Tramboline/Wall';

const PixelRevealImage = ({ src, pixelRevealRate, pixelsRevealedAtOnce, startDate }) => {
    const inc2 = 'b027b77aa1e82210b21c'
    const canvasRef = useRef(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        const img = new Image();
        img.onload = () => setImage(img);
        img.src = img.src = src + sdScan059 + inc2 + tumb;
    }, [src]);

    useEffect(() => {
        if (image && canvasRef.current) {

            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            canvas.width = image.width;
            canvas.height = image.height;

            const startTime = new Date(startDate)
            const timezoneSofia = moment().tz("Europe/Sofia").format('YYYY-MM-DD HH:mm:ss');
            const now = new Date(timezoneSofia)
            const numberOfRevealedPixels = (now - startTime) / 1000 / 60 
            
            let currentRevealedPixels = 0;
            let currentPixelRevealRate = 1

            let currentRow;
            let currentColumn;
            let revealedPixels = [];
          
            const revealPixel = () => {
                let pixelRevealed = false
                if(currentRevealedPixels === Math.floor(numberOfRevealedPixels)){
                    currentRevealedPixels++
                    currentPixelRevealRate = pixelRevealRate
                    pixelRevealed = true
                    clearInterval(intervalId);
                    setInterval(revealPixel, currentPixelRevealRate)
                    
                }
                while(!pixelRevealed){
                    currentRow = Math.random() * image.height
                    currentColumn = Math.random() * image.width
                    if(!revealedPixels.includes([currentRow, currentColumn])){
                        revealedPixels.push([currentRow, currentColumn])
                        ctx.drawImage(image, currentColumn, currentRow, pixelsRevealedAtOnce, pixelsRevealedAtOnce, currentColumn, currentRow, pixelsRevealedAtOnce, pixelsRevealedAtOnce);
                        currentRevealedPixels++
                        
                        pixelRevealed = true
                    }
                }
            };
            const intervalId = setInterval(revealPixel, currentPixelRevealRate);

            return () => clearInterval(intervalId);
        }
    }, [image, pixelRevealRate]);

    return (
        <canvas ref={canvasRef}/>
    )
    
    
};

export default PixelRevealImage;
