import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import Tasks from './pages/Tasks';
import Reward from './pages/Reward';


function App() {
 
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', 'synthwave');
  }, []);


  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/tasks' element={<Tasks />} />
        <Route path='/reward' element={<Reward />} />
      </Routes>
    </BrowserRouter>      
  );
}

export default App;
