import React, {useEffect, useState} from "react";
import { FaDiscord, FaXTwitter, FaCircleCheck, FaRetweet, FaArrowRightLong } from "react-icons/fa6";
import TopBar from "../components/TopBar";
import OrangeIconGradient from "../components/OrangeIconGradient";
const Step = ({text, stepName, button, authenticateStep, isLoading, prevStepVerified, isVerified,  link = ''}) => {
    
    if(isVerified){
        return(
            <div className="w-full">
                <span className="float-left">{text}</span> 
                <span className="float-right"> 
                    <FaCircleCheck className="md:mr-1 mr-2"/>
                </span>
            </div> 
        )
    }

    if(!prevStepVerified){
        return(
            <div className="w-full">
            <span className="float-left">{text}</span> 
            <div className="float-right" data-tip="Do all previous steps!">
                <button className="cursor-not-allowed" disabled>
                    <span>{button}</span>
                </button> 
            </div>
        </div> 
        )
    }

    if(isLoading){
        return(
            <div className="w-full">
                <span className="float-left">{text}</span> 
                <span className="float-right mr-2"> 
                    <span className="loading loading-spinner text-success loading-md" ></span>
                </span>
            </div> 
        )
    }

    return(
        <div className="w-full"  >
            <span className="float-left">{text}</span> 
            <button className="float-right" onClick={() => authenticateStep(stepName)}>
                <a  href={link} target="_blank">  
                    <span className="glowing-text3 font-black inline-block mr-5 md:text-4xl/[40px] sm:text-4xl text-3xl hover:font-black">&#10230;</span>
                    {button}
                </a>
            </button>
        </div> 
    )
}

const Tasks = () => {
    const [isFollowing, setIsFollowing] = useState(false)
    const [isFollowingLoad, setIsFollowingLoad] = useState(false)

    const [isPosted, setIsPosted] = useState(false)
    const [isPostedLoad, setIsPostedLoad] = useState(false)
    
    const [isDiscordJoined, setIsDiscordJoined] = useState(false)
    const [isDiscordJoinedLoad, setIsDiscordJoinedLoad] = useState(false)

    const tweetUrl = `https://twitter.com/CryptoSboobs/status/1767219802681074164`
    const tweetText = "I almost got my CryptoSboobs reward! Come to www.cryptosboobs.com and get yours!" 
    const postTweet = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetUrl}`

    const tweetUsername = 'CryptoSboobs'
    const followUrl = `https://twitter.com/intent/follow?region=follow_link&screen_name=${tweetUsername}`

    const discordUrl = "https://discord.gg/6CqbHz9Th9"

    const authenticateStep = (step) => {
        if(step === 'isFollowing'){
            setIsFollowingLoad(true)
            setTimeout(() => {
                setIsFollowingLoad(false)
                setIsFollowing(true)
                localStorage.setItem('isFollowing', true)
            }, 20000)
        }else if(step === 'isDiscordJoined'){
            setIsDiscordJoinedLoad(true)
            setTimeout(() => {
                setIsDiscordJoinedLoad(false)
                setIsDiscordJoined(true)
                localStorage.setItem('isDiscordJoined', true)
            }, 20000)
        }else if(step === 'isPosted'){
            setIsPostedLoad(true)
            setTimeout(() => {
                setIsPostedLoad(false)
                setIsPosted(true)
                localStorage.setItem('isPosted', true)
            }, 20000)
        }
    } 

    useEffect(() => {
        if(localStorage.getItem('isFollowing')){
            setIsFollowing(true)
            if(localStorage.getItem('isPosted')){
                setIsPosted(true)
                if(localStorage.getItem('isDiscordJoined')){
                    setIsDiscordJoined(true)  
                }
            }
        }
    })

    return(
        <div className="mockup-browser border bg-base-300">
            <OrangeIconGradient />
           <TopBar />
            <div className="flex justify-center items-center h-screen flex-col px-4 py-16 bg-base-200">       
                <div>
                    <h1 className="xl:text-6xl md:text-5xl sm:text-4xl text-2xl mb-20 font-semibold">Follow the steps and get your <span className=' glowing-text'>#Xiting</span> reward!</h1>
                    <ul className="steps steps-vertical  steps-container md:mt-20 mt-0  w-11/12 ">
                        <li className='md:text-3xl sm:text-2xl text-1xl step step-success mb-2'>
                            <Step 
                                text="Follow us on Twitter" 
                                stepName="isFollowing" 
                                button={<FaXTwitter className="inline-block md:text-4xl/[40px] sm:text-4xl text-3xl hover-icon"/> } 
                                isLoading={isFollowingLoad}
                                authenticateStep={authenticateStep}
                                prevStepVerified={true}
                                isVerified={isFollowing}
                                link={followUrl}
                            />
                        </li>
                        <li className={`md:text-3xl sm:text-2xl text-1xl ${isFollowing ? 'step step-success' : 'step'}`}>
                            <Step 
                                text="Repost one of our posts" 
                                stepName="isPosted" 
                                button={<FaRetweet className="inline-block md:text-4xl/[40px] sm:text-4xl text-3xl hover-icon"/>} 
                                authenticateStep={authenticateStep}
                                prevStepVerified={isFollowing}
                                isVerified={isPosted}
                                isLoading={isPostedLoad}
                                link={postTweet}
                            />
                        </li>
                        <li className={`md:text-3xl sm:text-2xl text-1xl ${isPosted ? 'step step-success' : 'step'}`}>
                            <Step 
                                text="Join us on Discord" 
                                stepName="isDiscordJoined" 
                                button={ <FaDiscord className="inline-block md:text-4xl/[40px] sm:text-4xl text-3xl hover-icon"  /> } 
                                authenticateStep={authenticateStep}
                                isLoading={isDiscordJoinedLoad}
                                prevStepVerified={isPosted}
                                isVerified={isDiscordJoined}
                                link={discordUrl}
                            />
                        </li>
                        
                        <li className={`md:text-3xl sm:text-2xl text-1xl ${isDiscordJoined ? 'step step-success cursor-pointer' : 'step cursor-not-allowed'}`}>
                            <div className="lg:tooltip" data-tip={isDiscordJoined ? '' : "Finnish all the steps to open this feature!"}>
                                <a href={isDiscordJoined ? '/reward' : ''} className={isDiscordJoined ? "" : 'btn-disabled'}>
                                    <span className='glowing-text3'>Get your reward</span>
                                </a> 
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
      </div>
    )
}

export default Tasks