import React from "react";
import TopBar from "../components/TopBar";
import Counter from '../components/Counter';
import { FaChevronRight } from "react-icons/fa6";

const HomePage = () => {
    return(
        <div className="mockup-browser border bg-base-300">
            <TopBar/>
            <div className="relative min-h-screen bg-base-200">       
                <div className="flex justify-around items-center flex-col px-4 py-16 bg-base-200 content-container">
                    <h1 className="xl:text-6xl lg:text-5xl text-4xl text-center font-semibold">Something <span className=' glowing-text'>#Xiting</span> is being cooked...</h1>
                    <Counter />
                    <h1><a href='/tasks' className='text-4xl font-semibold glowing-text2'>Discover <span className="right-arrow"><FaChevronRight /></span></a></h1>
                </div>
            </div>
        </div>
    )
}

export default HomePage