import React from "react";

const OrangeIconGradient = () => {
    return(
        <svg width="0" height="0">
            <linearGradient id="orange-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                <stop stopColor="orange" offset="0%" />
                <stop stopColor="red" offset="100%" />
            </linearGradient>
        </svg>
    )
}

export default OrangeIconGradient